.story {
    .col {
        &:nth-child(2n + 2) {
            .story {
                &__thumb {
                    span {
                        background-image: linear-gradient(to right, #00afff, #3bbbff, #5ac6ff, #75d1ff, #8edcff);
                    }
                }
            }
        }
        &:nth-child(3n + 1) {
            .story {
                &__thumb {
                    span {
                        background-image: linear-gradient(to right, #a650ff, #b46cff, #c285ff, #ce9eff, #dab6ff);
                    }
                }
            }
        }
    }
    &__item {
        // &.style2 {
        //     .story__content h4 {
        //         margin-bottom: 0;
        //         @extend %text-1;
        //     }
        // }
    }
    &__inner {
        @extend %bg-white;
        padding: 15px;
        @include add-prefix(border-radius, (4px));
        @include add-prefix(transition, (all .3s ease));
        &:hover {
            box-shadow: 0 10px 10px rgba($shadow-color, $alpha: .10);
            .story {
                &__thumb {
                    img {
                        @include add-prefix(transform, (scale(1.2)));
                    }
                }
                &__content {
                    h4 {
                        color: $theme-color;
                        text-decoration: underline;
                    }
                }
            }
        }
    }
    &__thumb {
        @extend %of-hidden;
        @extend %p-rel;
        img {
            @extend %w-100;
        }
        a {
            @extend %d-block;
        }
        span {
            @extend %white-color;
            // @extend %bg-theme;
            padding-block: 1.5px;
            background-image: linear-gradient(to right, #fd3f84, #ff5d7f, #ff777f, #ff8e84, #ffa38d);
        }
    }
    &__content {
        padding: 20px 15px;
        h4 {
            line-height: 1.3;
            @extend %text-2;
        }
        &--author {
            @extend %flex;
            @extend %align-items-center;
            @extend %mt-10;
            a {
                @extend %d-block;
                img {
                    @extend %w-100;
                }
            }
        }
        &--thumb {
            width: 55px;
            img {
                @include add-prefix(border-radius, (100px));
            }
        }
        &--content {
            h6 {
                @extend %mb-0;
            }
            p {
                @extend %mb-0;
                @include font-size(14px);
                i {
                    @include font-size(14px);
                }
            }
        }
        &--gallery {
            @extend %flex;
            @extend %justify-between;
            @extend %w-100;
            li {
                width: calc(100% / 3);
                overflow: hidden;
                a {
                    @extend %d-inline-block;
                    @extend %w-100;
                    img {
                        @extend %w-100;
                        @extend %d-inline-block;
                        @extend %m-5;
                    }
                }
            }
        }
        ul {
            li {
                &.bg-theme {
                    background: $theme-color;
                    border-radius: 100%;
                    width: 30px;
                    height: 30px;
                    line-height: 30px;
                    text-align: center;
                    color: #fff;
                    font-size: 14px;
                    font-weight: 700;
                }
            }
        }
    }
    &--style2 {
        .story {
            &__content {
                h4 {
                    margin-bottom: 0;
                    @extend %text-1;
                }
            }
            &--theme-color {
                .story__thumb span {
                    background: $theme-color;
                }
                .story__content ul.img-stack li.bg-theme {
                    background: $theme-color;

                }
            }
            &--theme-color2 {
                .story__thumb span {
                    background: $theme-color2 !important;
                }
                .story__content ul.img-stack li.bg-theme {
                    background: $theme-color2;

                }
            }
            &--theme-color3 {
                .story__thumb span {
                    background: $theme-color3 !important;
                }
                .story__content ul.img-stack li.bg-theme {
                    background: $theme-color3;

                }
            }
            &--theme-color4 {
                .story__thumb span {
                    background: $theme-color4;
                }
                .story__content ul.img-stack li.bg-theme {
                    background: $theme-color4;

                }
            }
            &--theme-color5 {
                .story__thumb span {
                    background: $theme-color5;
                }
                .story__content ul.img-stack li.bg-theme {
                    background: $theme-color5;

                }
            }
            &--theme-color6 {
                .story__thumb span {
                    background: $theme-color6;
                }
                .story__content ul.img-stack li.bg-theme {
                    background: $theme-color6;

                }
            }
            &--theme-color7 {
                .story__thumb span {
                    background: $theme-color7;
                }
                .story__content ul.img-stack li.bg-theme {
                    background: $theme-color7;

                }
            }
            &--theme-color8 {
                .story__thumb span {
                    background: $theme-color8;
                }
                .story__content ul.img-stack li.bg-theme {
                    background: $theme-color8;

                }
            }
            &--theme-color9 {
                .story__thumb span {
                    background: $theme-color9;
                }
                .story__content ul.img-stack li.bg-theme {
                    background: $theme-color9;

                }
            }
            &--theme-color10 {
                .story__thumb span {
                    background: $theme-color10;
                }
                .story__content ul.img-stack li.bg-theme {
                    background: $theme-color10;

                }
            }
            &--theme-color11 {
                .story__thumb span {
                    background: $theme-color11;
                }
                .story__content ul.img-stack li.bg-theme {
                    background: $theme-color11;

                }
            }
            &--theme-color12 {
                .story__thumb span {
                    background: $theme-color12;
                }
                .story__content ul.img-stack li.bg-theme {
                    background: $theme-color12;

                }
            }
        }
    }
    &.community {
        .story {
            &__thumb {
                span {
                    background: $theme-color !important;
                }
            }
        }
    }
    .section__wrapper {
        margin-top: 18px;
    }
}