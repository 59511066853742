p {
  font-size: 1.4rem !important;
  color: black !important;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Poppins", sans-serif !important;
}

* {
  font-family: "Poppins", sans-serif;
}

.connet_wallet_popup_cnt_ {
  display: flex;
}
.connet_wallet_popup_cnt_ .con_wal_left_cnt {
  border-right: 1px solid;
  border-color: #ccc;
  background: #fff;
  padding: 2.5rem;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  display: flex;
  flex-direction: column;
}
.connet_wallet_popup_cnt_ .con_wal_right_cnt {
  width: 100%;
  background-color: #f3f7f9;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
}
@media (min-width: 576px) {
  .modal-dialog {
    max-width: 800px;
  }
}
.con_wal_right_cnt_main_ {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding: 1rem;
  border: none;
  gap: 0.5rem;
}
.con_wal_right_cnt_main_mobile_ {
  display: grid;
  padding: 1rem;
  border: none;
  gap: 0.5rem;
}
.con_wal_right_cnt_main_ .con_wal_box {
  background: #fff;
  border: 1px solid #d8d8d8;
  border-radius: 10px;
  flex-flow: row nowrap;
  gap: 1rem;
  padding: 1rem;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.con_wal_box .con_wal_box_icon_ {
  border: 1px solid #eaeaea;
  width: 48px;
  height: 48px;
  border-radius: 12px;
  padding: 8px;
  align-items: center;
  display: flex;
}
.con_wal_box .con_wal_box_cnt h5 {
  font-weight: 400;
  letter-spacing: 0.7px;
  line-height: 0;
  padding-top: 5px;
  font-size: 1rem;
}
.con_wal_right_cnt .alert {
  margin: 0 20px;
  border-radius: 10px;
  border: 1px solid #856404;
}
.con_wal_right_cnt .alert h6 {
  font-size: 0.9rem;
  margin: 0;
}
.con_wal_right_cnt .alert .con_wal_link {
  font-size: 14px;
  text-decoration: underline;
}
.con_wal_right_cnt .alert .can_wal_info {
  position: absolute;
  right: 10px;
  top: 10px;
}
.connet_wallet_popup_cnt .con_wal_logo img {
  width: 50%;
  margin-bottom: 20px;
}
.connet_wallet_popup_cnt_ .con_wal_left_cnt h6 {
  color: #787a9b;
  font-size: 18px;
  margin-bottom: 20px;
  padding-top: 15px;
}
.connet_wallet_popup_cnt_ .con_wal_left_cnt p {
  color: #787a9b;
}

.con_wal_right_cnt .con_wal_tit {
  padding: 1rem;
  border-color: #eaeaea;
}

.con_wal_right_cnt .con_wal_tit h2 {
  font-size: 18px;
  margin: 0;
  font-weight: 600;
}
.con_wal_right_cnt .con_wal_tit .close {
  position: absolute;
  right: 10px;
  top: 8px;
  width: 35px;
  background: #bebebe;
  text-shadow: none;
  height: 35px;
  border-radius: 50px;
}
